import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { closeModal, useAppDispatch } from '@/store';

import { DENGIMARKET_OLX_URL, DENGIMARKET_ENABLED_LANG, DENGIMARKET_LOGIN_URL } from '@/shared/config/app';
import { HEAD_HUNTER_LINK, localesTitle } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { GradientPngIcon } from '@/shared/icons/gradient-png-icon';
import PersonIcon from '@/shared/icons/person-icon';
import { Button } from '@/shared/ui/button';
import { LinkText } from '@/shared/ui/link-text';
import { ELinkTextVariantType } from '@/shared/ui/link-text/types';
import { LocaleSwitcher } from '@/shared/ui/locale-switcher';
import LocationScrollLink from '@/shared/ui/location-scroll-link';

import * as Styled from './styled';

type TActionMenuSection = {
    showPhoneModal?: () => void;
};

export const ActionMenuSection: FC<TActionMenuSection> = (props) => {
    const { showPhoneModal } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslate();
    const route = useRouter();
    const { locale, locales } = route;

    const handleClick = () => {
        dispatch(closeModal());
        route.push('/departments');
    };

    return (
        <Styled.Wrapper>
            <div className="links-section">
                <LocationScrollLink
                    icon={<GradientPngIcon icon="location" />}
                    onClick={handleClick}
                    title={t('Отделения')}
                    variant="simple"
                    to="departments"
                />

                <Button onClick={showPhoneModal} variant="unborded">
                    <div className="link-logo-menu mr-6">
                        <GradientPngIcon icon="phone" />
                    </div>

                    <span className="action-button-text">{t('Позвонить')}</span>
                </Button>

                <LinkText href={DENGIMARKET_OLX_URL} target="_blank" variant={ELinkTextVariantType.UNBORDED}>
                    <div className="link-logo-menu mr-6">
                        <GradientPngIcon icon="shop" />
                    </div>

                    <span className="action-button-text">{t('Купить')}</span>
                </LinkText>

                <LinkText href={HEAD_HUNTER_LINK} target="_blank" variant={ELinkTextVariantType.UNBORDED}>
                    <div className="link-logo-menu mr-6">
                        <GradientPngIcon icon="career" />
                    </div>

                    <span className="action-button-text">{t('Карьера')}</span>
                </LinkText>
            </div>

            <div className="login-section">
                <LinkText
                    className="login-link"
                    href={DENGIMARKET_LOGIN_URL}
                    target="_blank"
                    variant={ELinkTextVariantType.GRADIENT_BRAND_GREEN}
                >
                    <div className="person-icon">
                        <PersonIcon />
                    </div>

                    <span>{t('Личный кабинет')}</span>
                </LinkText>

                <div className="notation-text">{t('sideMenuNotation')}</div>
            </div>

            {DENGIMARKET_ENABLED_LANG && (
                <div className="locale-switcher-section">
                    <LocaleSwitcher
                        activeLocale={locale as string}
                        locales={locales as string[]}
                        namedLocale={localesTitle}
                    />
                </div>
            )}
        </Styled.Wrapper>
    );
};
