import { ReactNode } from 'react';

import { ContainerContent } from './container-content';
import { DefaultCard } from './default-card';
import * as Styled from './styled';

type TProps = {
    children: ReactNode;
    header: ReactNode;
    footer: ReactNode;
};

const Layout = ({ footer, header, children }: TProps) => {
    return (
        <Styled.Wrapper>
            {header}
            <Styled.WrapperContent>{children}</Styled.WrapperContent>
            {footer}
        </Styled.Wrapper>
    );
};

Layout.Content = ContainerContent;
Layout.DefaultCard = DefaultCard;

export const LayoutDefault = Layout;
