import React from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { LinkText } from '@/shared/ui/link-text';
import { ELinkTextVariantType } from '@/shared/ui/link-text/types';

import * as Styled from './styled';

export const FooterNavBar = () => {
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <LinkText href="/tehnika-pod-zalog" variant={ELinkTextVariantType.BRAND_GREEN_UNBORDED}>
                {t('Оценить технику')}
            </LinkText>
            <LinkText href="/zoloto-pod-zalog" variant={ELinkTextVariantType.BRAND_GREEN_UNBORDED}>
                {t('Оценить золото')}
            </LinkText>
            <LinkText href="/category/stati" variant={ELinkTextVariantType.BRAND_GREEN_UNBORDED}>
                {t('Статьи')}
            </LinkText>
            <LinkText href="/documents" variant={ELinkTextVariantType.BRAND_GREEN_UNBORDED}>
                {t('Документы')}
            </LinkText>
            <LinkText href="/promotions" variant={ELinkTextVariantType.BRAND_GREEN_UNBORDED}>
                {t('Telegram Market')}
            </LinkText>
        </Styled.Wrapper>
    );
};
