import { FC, ReactNode } from 'react';

import * as Styled from './styled';

type TProps = {
    children: ReactNode;
    isCenterContent?: boolean;
};

export const DefaultCard: FC<TProps> = ({ children, isCenterContent = false }) => {
    return <Styled.Wrapper $isCenterContent={isCenterContent}>{children}</Styled.Wrapper>;
};
