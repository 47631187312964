import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: end;

    .person-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ${mq.lt.lg} {
        display: none;
    }
`;

export const LinksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 600px;
    gap: 8px;
    justify-content: space-between;

    .space {
        width: 80px;
        ${mq.lt.xl} {
            width: 4px;
        }
    }

    .action-button-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
    }

    .personal-button {
        flex-wrap: nowrap;

        span {
            text-wrap: nowrap;
        }
    }
`;
