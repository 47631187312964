import { styled } from 'styled-components';

export const Wrapper = styled.div<{ $isCenterContent: boolean }>`
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 5%);
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: ${({ $isCenterContent }) => ($isCenterContent ? 'center' : 'start')};
`;
